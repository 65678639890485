@import "/node_modules/typeface-montserrat/index.css";


.p-datatable-wrapper-ponto {
  display: inline-block;
  height: 750px;
  overflow: auto
}

.p-datatable-columns-ponto table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.fa-bullseye {
  animation:fa-bullseye 5s ease infinite;
}

.inline-block-child {
  display: inline-block;
}

@keyframes fa-bullseye {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
}

.datatable-responsive-demo .vencido {
    background-color: #ffd5d5 !important;
}

.datatable-responsive-demo .pago {
    background-color: #80d4a4 !important;
}

.datatable-responsive-demo .pontoInconsistente {
    font-weight: 700;
    color: #FF5252;
}

.p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    font-size: 13px;
}

.p-fieldset-legend>a, .p-fieldset-legend>span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9pt !important;
}

.p-fieldset .p-fieldset-legend {
    padding: 0.5rem !important;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
}

.p-datatable .p-datatable-thead > tr > th {
    font-size: 13px;
    font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.5rem !important;
}
.p-datatable .p-datatable-tbody > tr > td {
    font-size: 13px;
    font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.5rem !important;
}


.p-datatable-tbody > tr > td > .p-column-title {
  display: none;
}

/*
.p-datatable-responsive-demo .p-datatable-wrapper .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
*/

@media screen and (max-width: 40em) {
    
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
      padding: .4rem;
      min-width: 30%;
      display: inline-block;
      margin: -.4em 1em -.4em -.4rem;
      font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
  }

}

body {
  font-style: normal!important;
}

.p-datatable .p-paginator-bottom{
  border: 0px!important;
}


.MuiContainer-root {
  padding: 1px !important;
}

.MuiToolbar-regular {
  min-height: 50px!important;
}

.makeStyles-divPrincipal-5 {
  padding: 0 50px 50px 50px!important;
}

.MuiFormControl-root2{
  display: block!important;
}

/* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
 
  .margin-01 {
    margin-top: -85px !important;
  }

  .img-caroucel {
    width: 100%!important;
    height: 100%!important;
  }
  .img-2 {
    width: 180px!important;
  }

  .w-85{
    width: 85%!important;
  }

  .w-100{
    width: 100%!important;
  }
 
  .MuiGrid-grid-xs-7 {
    max-width: 100%!important;
    flex-basis: 100%!important;
  }

  .MuiTypography-h3 {
    font-size: 2.3rem!important;
  }
  
  .MuiTypography-h6 {
      font-size: 22px;
      line-height: 1.6;
      letter-spacing: -0.05px;
  }

  .displayNone{
    display: none;
  }

  .MuiBadge-badge {
    margin-top: 7px!important;
  }
  

  .paddingX-10 {
    margin-right: 0px!important;
    margin-left: 0px!important;
  }

  .paddingX-35{
    padding: 10px!important;
  }

  .paddingY-10{
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }


}

.datatable-rowexpansion-demo {
    padding: 1rem;
}

.warnColor {
  color: crimson,
  
}